<template>
    <AppProvider>
        <router-view/>
    </AppProvider>
</template>

<script>
import { defineComponent } from 'vue'
import AppProvider from '@/components/AppProvider.vue'

export default defineComponent({
    name: 'App',
    components: {
        AppProvider,
    },
})
</script>

<style>
html, body, #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    height: 100%;
}

#nav {
    padding: 30px;
}

#nav a {
    font-weight: bold;
    color: #2c3e50;
}

#nav a.router-link-exact-active {
    color: #42b983;
}
</style>
